<template>
    <a-layout style="height:100%">
        <a-layout-header style="height:74px">
            <div class="header">
                <div class="logo">
                    <router-link to="/home">
                        <img src="@/assets/szlogo2.png" alt="">
                    </router-link>
                </div>
            </div>
        </a-layout-header>
        <router-view />
    </a-layout>
</template>

<script>
export default {
    
}
</script>
<style lang="scss">
@import '../../scss/analysis.scss';
</style>